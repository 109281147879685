import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { emptyFunction, get2Decimal, getAssetUrl } from '../../utils'
import { useDispatch, useSelector } from 'react-redux'
import { IoMailOutline } from 'react-icons/io5'
import './BlinkingDot.css'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Modal,
  useMediaQuery,
} from '@mui/material'
import { DropDown, LightDarkMode2 } from '../../pages/landing/component'
import { ICurrentDomain } from '../../types/interfaces/currentDomain'
import { IEnable } from '../../types/interfaces/themeConfiguration'
import { IChangePasswordPayload } from '../../types/interfaces/login'
import { toast } from 'react-toastify'
import { ChangePassword } from '../changePassword'
import { IAppConfiguration } from '../../types/interfaces/appConfiguration'
import { DEPOSIT_WITHDRAW_WHATSAPP_REDIRECT, URLType, makeGetHttpCallAsync, makePutHttpCallAsync } from '../../api'
import { APP_UPDATE_IS_MAINTENANCE, APP_UPDATE_USE_SOCKET } from '../../redux'
import { MdClose } from 'react-icons/md'
import { isMobileScreensix } from '../../utils/internalUtils'
import { USER_TYPES_ALL_OBJ } from '../../redux/type/AppConstant'
import {
  AccountBalance,
  AccountTreeSharp,
  CasinoRounded,
  Lock,
  LogoutTwoTone,
  Money,
  NotificationAddTwoTone,
  Receipt,
  Refresh,
  TranscribeRounded,
  Wallet,
} from '@mui/icons-material'
import Person from '@mui/icons-material/Person'
const avatarImage = getAssetUrl('header/avatar.svg')
const userImage = getAssetUrl('header/avatar.svg')

interface INavbarProfile {
  logoutFunc?: (navigate: any, userType: String, currentDomain: any) => void
  userType?: String
  matchBet: any
  matchBetPagination: any
  demoLogin: boolean
  logoutUser: any
  currentDomain: ICurrentDomain
  isDarkMode?: boolean
  isLogin?: boolean
  changePassword: any
  updateThemeToggle?: (isDarkMode?: boolean) => void
  lang?: string
  appConfiguration: IAppConfiguration
  languageUpdate?: (lang: string) => void
}

export default function NavbarProfileMenus(props: INavbarProfile) {
  const userData = useSelector((state: any) => state.login.userData)
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)
  const [isOpenModalChangePassword, setIsOpenModalChangePassword] =
    React.useState<boolean>(false)
  const currentDomain = useSelector((state: any) => state.currentDomain)
  const enableFeatures: IEnable = useSelector(
    (state: any) => state.appThemeConfiguration.enable,
  )
  const isSmallestScreen = useMediaQuery('(max-width:350px)')
  const { languages } = useSelector((state: any) => state.appCommonTheme)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [activeTabIndex, setActivceTabIndex] = useState<any>(
    JSON.parse(localStorage.getItem('activeTabIndex') ?? '') || 1,
  )
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setIsOpen(true)
    setAnchorElUser(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorElUser(null)
    setTimeout(() => {
      setIsOpen(false)
    }, 500)
  }

  const handleLogout = () => {
    if (props.logoutFunc) {
      props.logoutFunc(navigate, props.userType || '', currentDomain)
    }
    setAnchorElUser(null)
  }

  const updateMaintenance = (event: any) => {
    event.stopPropagation()
    makePutHttpCallAsync(
      dispatch,
      'api/administrator/setting/maintenance',
      URLType.master,
      JSON.stringify({
        maintenanceMode: !props.appConfiguration.isMaintenance,
        transactionCode: transactionCode,
      }),
      '',
      true,
      '',
      true,
      `Maintence ${props.appConfiguration.isMaintenance ? 'Off' : 'On'}`,
    )
      .then(() => {
        dispatch({
          type: APP_UPDATE_IS_MAINTENANCE,
          payload: !props.appConfiguration.isMaintenance,
        })
        handleClose()
        setMaintenceModal(false)
      })
      .catch(() => {
        handleClose()
        setMaintenceModal(false)
      })
  }

  const [socketModal, setSocketModal] = useState(false)
  const [maintenceModal, setMaintenceModal] = useState(false)
  const [transactionCode, setTransactionCode] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const updateSocketUse = (event: any) => {
    event.stopPropagation()
    makePutHttpCallAsync(
      dispatch,
      'api/administrator/setting/useSocket',
      URLType.master,
      JSON.stringify({
        socket: !props.appConfiguration.useSocket,
        transactionCode: transactionCode,
      }),
      '',
      true,
      '',
      true,
      `Socket Connection ${props.appConfiguration.useSocket ? 'Off' : 'On'}`,
    )
      .then(() => {
        dispatch({
          type: APP_UPDATE_USE_SOCKET,
          payload: !props.appConfiguration.useSocket,
        })
        handleClose()
        setSocketModal(false)
      })
      .catch(() => {
        handleClose()
        setSocketModal(false)
      })
  }

  const refreshUser = () => {
    makeGetHttpCallAsync(
      dispatch,
      'api/administrator/broadcast/refreshPage',
      '',
      URLType.master,
      '',
      true,
      '',
      true,
      'User Refreshed',
    ).then(emptyFunction)
  }

  const getUserTypeName = (userType: string, domainType: string) => {
    switch (userType) {
      case USER_TYPES_ALL_OBJ.whiteLabel:
        return _getLocalString('LOC_WHITELABEL')

      case USER_TYPES_ALL_OBJ.admin:
        return _getLocalString('LOC_ADMIN')

      case USER_TYPES_ALL_OBJ.administrator:
        return _getLocalString('LOC_ADMINISTRATOR_SMALL')

      case USER_TYPES_ALL_OBJ.agent:
        return _getLocalString('LOC_AGENT')

      case USER_TYPES_ALL_OBJ.master:
        return _getLocalString('LOC_MASTER')

      case USER_TYPES_ALL_OBJ.superMaster:
        return _getLocalString('LOC_SUPER_MASTER')

      case USER_TYPES_ALL_OBJ.user:
        return _getLocalString('LOC_WELCOME')
    }
  }

  const changePasswordFunc = (
    payload: IChangePasswordPayload,
    currentDomain: any,
    setShowTransactionCode: any,
    updateTransactionCode: any,
    cb: any,
  ) => {
    props.changePassword(
      payload,
      currentDomain as any,
      setShowTransactionCode,
      updateTransactionCode,
      cb,
    )
  }

  useEffect(() => {
    localStorage.setItem('activeTabIndex', activeTabIndex)
  }, [activeTabIndex])

  const changePasswordFuncData = (_data: boolean) => {
    return setIsOpenModalChangePassword(_data)
  }

  const menuItemClick = () => {
    if (props.demoLogin) {
      return toast.error(_getLocalString('LOC_DEMO_ACCOUNT_ERROR_MESSAGE'))
    }
  }

  const { registration } = useSelector(
    (state: any) => state.appThemeConfiguration,
  )
  const { communicationChannel } = useSelector((state: any) => state.login)

  const depositClick = () => {
    if(DEPOSIT_WITHDRAW_WHATSAPP_REDIRECT[props.currentDomain.domainName]) {
      return window.open(!communicationChannel?.depositWhatsapp ? registration?.whatsappNumber?.includes('https:')
      ? registration?.whatsappNumber
      : `https://wa.me/${registration?.whatsappNumber}`:
        communicationChannel?.depositWhatsapp?.includes('https:')
          ? communicationChannel?.depositWhatsapp
          : `https://wa.me/${communicationChannel?.depositWhatsapp}`,
        '_blank',
      )
    }

    return !props.demoLogin && navigate('/deposit')
  }

  const withdrawClick = () => {
    if(DEPOSIT_WITHDRAW_WHATSAPP_REDIRECT[props.currentDomain.domainName]) {
      return window.open(!communicationChannel?.depositWhatsapp ? registration?.whatsappNumber?.includes('https:')
      ? registration?.whatsappNumber
      : `https://wa.me/${registration?.whatsappNumber}`:
        communicationChannel?.withdrawWhatsapp?.includes('https:')
          ? communicationChannel?.withdrawWhatsapp
          : `https://wa.me/${communicationChannel?.withdrawWhatsapp}`,
        '_blank',
      )
    }

    return !props.demoLogin && navigate('/withdraw')
  }

  const svgStyle: React.CSSProperties = {
    '--nav-bar-icon-1': '#FF5733',
    '--nav-bar-icon-2': '#C70039',
    '--nav-bar-icon-3': '#900C3F',
    '--nav-bar-icon-4': '#581845',
  } as React.CSSProperties

  return (
    <React.Fragment>
      <div style={{ alignItems: 'center' }} className='flex'>
        <div style={svgStyle}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <Tooltip title='Profile'>
              <>
                <IconButton
                  disableRipple
                  onClick={handleOpenUserMenu}
                  sx={{ p: 0 }}
                  className='gap-2 md:!flex !hidden'
                >
                  <Avatar
                    alt='Remy Sharp'
                    src={avatarImage}
                    className='!w-10 !h-10'
                  />
                  <span className='text-base text-white capitalize'>
                    {props.demoLogin
                      ? 'Demo'
                      : isMobileScreensix()
                        ? userData.userName
                        : userData.userName.slice(0, 20)}
                  </span>
                  <span className='text-sm text-white md:text-xs '>
                    {!anchorElUser ? (
                      <ArrowDropDownIcon className='md:text-xs' />
                    ) : (
                      <ArrowDropUpIcon className='md:text-xs' />
                    )}
                  </span>
                </IconButton>
                <IconButton
                  disableRipple
                  onClick={handleOpenUserMenu}
                  className='gap-2 md:!hidden !flex bg-[linear-gradient(92.76deg,#FF2E15_-7.86%,#FF8A00_108.96%),linear-gradient(0deg,rgba(255,255,255,0.42),rgba(255,255,255,0.42))] justify-center items-center !p-0 !shadow-[0px_1px_4px_0px_#7B0F2F]'
                >
                  <Avatar
                    alt='Remy Sharp'
                    src={avatarImage}
                    className='!w-9 !h-9 '
                  />
                </IconButton>
                <span
                  onClick={handleOpenUserMenu}
                  className={`${isSmallestScreen ? '!hidden' : ''} md:!hidden lg:!hidden xl:!hidden text-xs text-white capitalize ml-1 `}
                >
                  {props.demoLogin ? 'Demo' : userData.userName.slice(0, 10)}
                </span>
                <span className='md:!hidden lg:!hidden xl:!hidden text-sm text-white '>
                  {!anchorElUser ? (
                    <ArrowDropDownIcon style={{ fontSize: '14px' }} />
                  ) : (
                    <ArrowDropUpIcon style={{ fontSize: '14px' }} />
                  )}
                </span>
              </>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorElUser}
            id='account-menu'
            open={!!anchorElUser && Boolean(anchorElUser) && isOpen}
            onClose={handleClose}
            onClick={handleClose}
            className='relative !mt-2 !pt-0'
            PaperProps={{
              className:
                'scrollbar_hide md:!top-14 !top-32 !rounded-2xl !bg-red-500 p-[1px] overflow bg-gradient-to-r from-[var(--nav-bar-popup-1)]  to-[var(--nav-bar-popup-2)] !max-h-[calc(100%-96px)]',
            }}
            MenuListProps={{
              className: ' !pt-0 !rounded-2xl !bg-white',
            }}
            disableAutoFocusItem={false}
            style={{ zIndex: 999 }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <div
              style={{
                position: 'absolute',
                zIndex: 999,
                right: '5px',
                top: '3px',
              }}
            >
              <IconButton
                onClick={handleClose}
                style={{ color: 'white', padding: 0 }}
              >
                <MdClose />
              </IconButton>
            </div>
            <MenuItem
              onClick={handleClose}
              className='flex flex-wrap !gap-4 !font-primary !text-white bg-gradient-to-r from-[var(--nav-bar-popup-1)] via-[var(--nav-bar-popup-2)] to-[var(--nav-bar-popup-3)] !py-4 !pl-5 !pr-8 !rounded-t-2xl'
            >
              <Avatar src={userImage} className='!w-14 !h-14' />
              <div className='flex flex-col'>
                <span className='!font-extrabold uppercase'>
                  {_getLocalString('LOC_HI')}{' '}
                  {props.demoLogin
                    ? _getLocalString('LOC_DEMO')
                    : userData.userName}
                  ,
                </span>
                <span className='text-base font-medium'>
                  {userData.userType !== 'user' && `You are ${' '}`}
                  {props.demoLogin
                    ? _getLocalString('LOC_DEMO_USER')
                    : getUserTypeName(userData.userType, currentDomain.type)}
                  <br />
                  {(userData.userType === USER_TYPES_ALL_OBJ.administrator ||
                    userData.userType === USER_TYPES_ALL_OBJ.administrator) && (
                    <span>
                      {_getLocalString('LOC_TYPE_IS')}{' '}
                      <b>{currentDomain.type}</b>
                    </span>
                  )}
                </span>
              </div>
              <div
                className='flex gap-3 !min-w-max items-center justify-center mt-2'
                onClick={(e) => e.stopPropagation()}
              >
                {isMobileScreensix() && (
                  <>
                    {isMobileScreensix() && enableFeatures?.isNightMode && (
                      <LightDarkMode2
                        matchBetPagination={[]}
                        demoLogin={props.demoLogin}
                        updateOpenLoginScreen={emptyFunction}
                        openLoginScreen={false}
                        key='nightMode2'
                        currentDomain={props.currentDomain}
                        matchBet={props.matchBet}
                        isDarkMode={props.isDarkMode}
                        updateThemeToggle={props.updateThemeToggle}
                        className='!text-red-500'
                      />
                    )}
                  </>
                )}
                {enableFeatures?.isLanguage && (
                  <DropDown
                    item={languages}
                    selectedValue={props.lang}
                    update={props.languageUpdate}
                  />
                )}
              </div>
            </MenuItem>

            <MenuItem className='!pt-5 !px-8 flex-col gap-3 hover:!bg-white'>
              <div className='flex items-center justify-between w-full'>
                <div className='flex flex-col md:!text-sm !text-base'>
                  <div className='leading-4 text-black font-dm_sans'>
                    {_getLocalString('LOC_WALLET_AMOUNT')}
                  </div>
                  <div className='text-[#4D4B4B] font-medium leading-4 font-primary'>
                    ({_getLocalString('LOC_INCLUSIVE_BONUS')})
                  </div>
                </div>
                <div className='font-dm_sans text-[#EC1C24] font-medium md:!text-sm !text-base'>
                  {get2Decimal(userData.pts)}
                </div>
              </div>
              <div className='flex items-center justify-between w-full'>
                <div className='flex flex-col md:!text-sm !text-base'>
                  <div className='leading-4 text-black font-dm_sans'>
                    {_getLocalString('LOC_NET_EXPOSURE')}
                  </div>
                </div>
                <div className='font-dm_sans text-[#EC1C24] font-medium md:!text-sm !text-base'>
                  {get2Decimal(userData.exposure)}
                </div>
              </div>
              {props.userType === USER_TYPES_ALL_OBJ.user && (
                <div className='flex items-center justify-between w-full'>
                  <div className='flex flex-col md:!text-sm !text-base'>
                    <div className='leading-4 text-black font-dm_sans'>
                      {_getLocalString('LOC_BONUS_SMALL')}
                    </div>
                  </div>
                  <div className='font-dm_sans text-[#EC1C24] font-medium md:!text-sm !text-base'>
                    {get2Decimal(userData.thirdPartyPts)}
                  </div>
                </div>
              )}
              <div className='flex items-center justify-between w-full'>
                <div className='flex flex-col md:!text-sm !text-base'>
                  <div className='leading-4 text-black font-dm_sans'>
                    {_getLocalString('LOC_AVAILABLE_WITHDRAWAL')}
                  </div>
                </div>
                <div className='font-dm_sans text-[#EC1C24] font-medium md:!text-sm !text-base'>
                  {get2Decimal(userData.availablePts)}
                </div>
              </div>
            </MenuItem>

            {props.userType === USER_TYPES_ALL_OBJ.user &&
              (enableFeatures?.depositButton ||
                enableFeatures?.withdrawButton) && (
                <MenuItem
                  onClick={menuItemClick}
                  style={{ borderTop: 'solid 0.2px #8080802e' }}
                  className='hover:!bg-white flex-col gap-2'
                >
                  <div className='flex items-center justify-between w-full'>
                    {enableFeatures?.depositButton && (
                      <div
                        onClick={depositClick}
                        className='rounded-xl bg-gradient-to-r from-[var(--nav-bar-popup-1)] via-[var(--nav-bar-popup-2)] to-[var(--nav-bar-popup-3)] font-medium text-white font-dm_sans md:text-sm text-base px-4 py-3 cursor-pointer flex gap-2 items-center justify-center text-center'
                      >
                        {_getLocalString('LOC_DEPOSIT')}
                      </div>
                    )}
                    {enableFeatures?.withdrawButton && (
                      <div
                        onClick={withdrawClick}
                        className='rounded-xl bg-gradient-to-r from-[var(--nav-bar-popup-1)] via-[var(--nav-bar-popup-2)] to-[var(--nav-bar-popup-3)] font-medium text-white font-dm_sans md:text-sm text-base px-4 py-3 cursor-pointer flex gap-2 items-center justify-center text-center'
                      >
                        <span className=''>
                          {_getLocalString('LOC_WITHDRAW')}
                        </span>
                      </div>
                    )}
                  </div>
                </MenuItem>
              )}

            <MenuItem
              onClick={handleLogout}
              style={{ borderTop: 'solid 0.2px #8080802e' }}
              className='!px-10 !py-3'
            >
              <ListItemIcon>
                <LogoutTwoTone className='text-[var(--theme-color)] !w-7 !h-7' />
              </ListItemIcon>
              <span className='text-black font-dm_sans md:!text-sm !text-base '>
                {_getLocalString('LOC_LOGOUT')}
              </span>
            </MenuItem>

            {userData.userType === USER_TYPES_ALL_OBJ.user && (
              <MenuItem
                style={{
                  borderTop: 'solid 0.2px #8080802e',
                  background: activeTabIndex === 1 ? 'var(--theme-color)' : '',
                }}
                onClick={() => {
                  setActivceTabIndex(1)
                  navigate('/accounts')
                  handleClose()
                }}
                className='!px-10 !py-3'
              >
                <ListItemIcon>
                  <AccountTreeSharp
                    className={` ${activeTabIndex === 1 ? 'text-[white]' : 'text-[var(--theme-color)]'}  !w-7 !h-7`}
                  />
                </ListItemIcon>
                <span
                  className={`${activeTabIndex === 1 ? 'text-[white]' : 'text-[black]'} font-dm_sans md:!text-sm !text-base `}
                >
                  {_getLocalString('LOC_ACCOUNT_STATEMENT')}
                </span>
              </MenuItem>
            )}

            {props.userType === 'user' &&
              props.currentDomain.type === 'b2c' && (
                <MenuItem
                  style={{
                    borderTop: 'solid 0.2px #8080802e',

                    background:
                      activeTabIndex === 2 ? 'var(--theme-color)' : '',
                  }}
                  onClick={() => {
                    if (props.demoLogin) {
                      return toast.error(
                        _getLocalString('LOC_DEMO_ACCOUNT_ERROR_MESSAGE'),
                      )
                    }
                    navigate('/transaction')
                    setActivceTabIndex(2)
                    handleClose()
                  }}
                  className='!px-10 !py-3'
                >
                  <ListItemIcon>
                    <TranscribeRounded
                      className={` ${activeTabIndex === 2 ? 'text-[white]' : 'text-[var(--theme-color)]'}  !w-7 !h-7`}
                    />
                  </ListItemIcon>
                  <span
                    className={`${activeTabIndex === 2 ? 'text-[white]' : 'text-[black]'} font-dm_sans md:!text-sm !text-base `}
                  >
                    {_getLocalString('LOC_TRANSACTION')}
                  </span>
                </MenuItem>
              )}

            <MenuItem
              onClick={() => {
                setActivceTabIndex(3)
                navigate('/settings/secureauth')
                handleClose()
              }}
              style={{
                borderTop: 'solid 0.2px #8080802e',
                background: activeTabIndex === 3 ? 'var(--theme-color)' : '',
              }}
              className='!px-10 !py-3'
            >
              <ListItemIcon>
                <Lock
                  className={` ${activeTabIndex === 3 ? 'text-[white]' : 'text-[var(--theme-color)]'}  !w-7 !h-7`}
                />
              </ListItemIcon>
              <span
                className={`${activeTabIndex === 3 ? 'text-[white]' : 'text-[black]'} font-dm_sans md:!text-sm !text-base `}
              >
                {_getLocalString('LOC_SECURITY_AUTH_VERIFICATION')}
              </span>
            </MenuItem>

            <MenuItem
              style={{
                borderTop: 'solid 0.2px #8080802e',
                background: activeTabIndex === 4 ? 'var(--theme-color)' : '',
              }}
              onClick={() => {
                setActivceTabIndex(4)
                navigate('/profile')
                handleClose()
              }}
              className='!px-10 !py-3'
            >
              <ListItemIcon>
                <Person
                  className={` ${activeTabIndex === 4 ? 'text-[white]' : 'text-[var(--theme-color)]'}  !w-7 !h-7`}
                />
              </ListItemIcon>
              <span
                className={`${activeTabIndex === 4 ? 'text-[white]' : 'text-[black]'} font-dm_sans md:!text-sm !text-base `}
              >
                {_getLocalString('LOC_PROFILE')}
              </span>
            </MenuItem>

            <MenuItem
              style={{
                borderTop: 'solid 0.2px #8080802e',
                background: activeTabIndex === 5 ? 'var(--theme-color)' : '',
              }}
              onClick={() => {
                setActivceTabIndex(5)

                if (props.demoLogin) {
                  return toast.error(
                    _getLocalString('LOC_DEMO_ACCOUNT_ERROR_MESSAGE'),
                  )
                }
                setIsOpenModalChangePassword(true)
                handleClose()
              }}
              className='!px-10 !py-3'
            >
              <ListItemIcon>
                <Person
                  className={` ${activeTabIndex === 5 ? 'text-[white]' : 'text-[var(--theme-color)]'}  !w-7 !h-7`}
                />
              </ListItemIcon>
              <span
                className={`${activeTabIndex === 5 ? 'text-[white]' : 'text-[black]'} font-dm_sans md:!text-sm !text-base `}
              >
                Change Password
              </span>
            </MenuItem>

            {props.userType === USER_TYPES_ALL_OBJ.whiteLabel && (
              <MenuItem
                onClick={() => {
                  setActivceTabIndex(6)

                  navigate('/user-hierarchy')
                  handleClose()
                }}
                style={{
                  borderTop: 'solid 0.2px #8080802e',
                  background: activeTabIndex === 6 ? 'var(--theme-color)' : '',
                }}
                className='!px-10 !py-3'
              >
                <ListItemIcon>
                  <AccountBalance
                    className={` ${activeTabIndex === 6 ? 'text-[white]' : 'text-[var(--theme-color)]'}  !w-7 !h-7`}
                  />
                </ListItemIcon>
                <span
                  className={`${activeTabIndex === 6 ? 'text-[white]' : 'text-[black]'} font-dm_sans md:!text-sm !text-base `}
                >
                  {_getLocalString('LOC_USER_HIERARCHY')}
                </span>
              </MenuItem>
            )}

            {props.currentDomain.domainName !== 'tataexch247.com' && (
              <MenuItem
                onClick={() => {
                  setActivceTabIndex(7)

                  navigate('/my-wallet')
                  handleClose()
                }}
                style={{
                  borderTop: 'solid 0.2px #8080802e',
                  background: activeTabIndex === 7 ? 'var(--theme-color)' : '',
                }}
                className='!px-10 !py-3'
              >
                <ListItemIcon>
                  <Wallet
                    className={` ${activeTabIndex === 7 ? 'text-[white]' : 'text-[var(--theme-color)]'}  !w-7 !h-7`}
                  />
                </ListItemIcon>
                <span
                  className={`${activeTabIndex === 7 ? 'text-[white]' : 'text-[black]'} font-dm_sans md:!text-sm !text-base `}
                >
                  {_getLocalString('LOC_MY_WALLET')}
                </span>
              </MenuItem>
            )}

            {userData.userType === USER_TYPES_ALL_OBJ.whiteLabel &&
              props.currentDomain.type === 'b2c' && (
                <MenuItem
                  onClick={() => {
                    setActivceTabIndex(8)

                    navigate('/bonus-offer')
                    handleClose()
                  }}
                  style={{
                    borderTop: 'solid 0.2px #8080802e',
                    background:
                      activeTabIndex === 8 ? 'var(--theme-color)' : '',
                  }}
                  className='!px-10 !py-3'
                >
                  <ListItemIcon>
                    <Money
                      className={` ${activeTabIndex === 8 ? 'text-[white]' : 'text-[var(--theme-color)]'}  !w-7 !h-7`}
                    />
                  </ListItemIcon>
                  <span
                    className={`${activeTabIndex === 8 ? 'text-[white]' : 'text-[black]'} font-dm_sans md:!text-sm !text-base `}
                  >
                    {_getLocalString('LOC_BONUS_WALLET')}
                  </span>
                </MenuItem>
              )}

            {userData.userType !== USER_TYPES_ALL_OBJ.user && (
              <MenuItem
                className='!px-10 !py-3'
                style={{
                  borderTop: 'solid 0.2px #8080802e',
                  background: activeTabIndex === 9 ? 'var(--theme-color)' : '',
                }}
                onClick={() => {
                  setActivceTabIndex(9)

                  if (userData.enable2fa) {
                    navigate('/transaction-code')
                    handleClose()
                  } else {
                    toast.info(
                      'Enable Secure Auth, to View/Update Transaction Code Anytime!',
                    )
                  }
                }}
              >
                <ListItemIcon>
                  <Lock
                    className={` ${activeTabIndex === 9 ? 'text-[white]' : 'text-[var(--theme-color)]'}  !w-7 !h-7`}
                  />
                </ListItemIcon>
                <span
                  className={`${activeTabIndex === 9 ? 'text-[white]' : 'text-[black]'} font-dm_sans md:!text-sm !text-base `}
                >
                  {_getLocalString('LOC_TRANSACTION_CODE')}
                </span>
              </MenuItem>
            )}

            {userData.userType === USER_TYPES_ALL_OBJ.user && (
              <MenuItem
                onClick={() => {
                  setActivceTabIndex(10)
                  navigate('/open-bets')
                  handleClose()
                }}
                style={{
                  borderTop: 'solid 0.2px #8080802e',
                  background: activeTabIndex === 10 ? 'var(--theme-color)' : '',
                }}
                className='!px-10 !py-3'
              >
                <ListItemIcon>
                  <CasinoRounded
                    className={` ${activeTabIndex === 10 ? 'text-[white]' : 'text-[var(--theme-color)]'}  !w-7 !h-7`}
                  />
                </ListItemIcon>
                <div className='flex items-center justify-between w-full'>
                  <span
                    className={`${activeTabIndex === 10 ? 'text-[white]' : 'text-[black]'} font-dm_sans md:!text-sm !text-base `}
                  >
                    {_getLocalString('LOC_OPEN_BETS')}
                  </span>
                  <span
                    className={`${activeTabIndex === 10 ? 'text-[black]' : 'text-[white]'} font-medium 
                    ${activeTabIndex === 10 ? 'bg-[white] ' : 'bg-gradient-to-r  from-[var(--paymentMethodColor)] via-[--paymentMethodColor] to-[var(--theme-color)]'}
            font-dm_sans  w-8 h-8 text-center items-center leading-8 rounded-full`}
                  >
                    {props.matchBetPagination &&
                    props.matchBetPagination.totalDocs
                      ? props.matchBetPagination.totalDocs
                      : props.matchBet.length > 50
                        ? '50+'
                        : props.matchBet.length}
                  </span>
                </div>
              </MenuItem>
            )}

            {userData.userType === USER_TYPES_ALL_OBJ.user && (
              <MenuItem
                onClick={() => {
                  setActivceTabIndex(11)
                  navigate('/bet-history')
                  handleClose()
                }}
                style={{
                  borderTop: 'solid 0.2px #8080802e',

                  background: activeTabIndex === 11 ? 'var(--theme-color)' : '',
                }}
                className='!px-10 !py-3'
              >
                <ListItemIcon>
                  <CasinoRounded
                    className={` ${activeTabIndex === 11 ? 'text-[white]' : 'text-[var(--theme-color)]'}  !w-7 !h-7`}
                  />
                </ListItemIcon>
                <div className='flex items-center justify-between w-full'>
                  <span
                    className={`${activeTabIndex === 11 ? 'text-[white]' : 'text-[black]'} font-dm_sans md:!text-sm !text-base `}
                  >
                    {_getLocalString('LOC_BET_HISTORY')}
                  </span>
                </div>
              </MenuItem>
            )}

            {userData.userType === USER_TYPES_ALL_OBJ.user && (
              <MenuItem
                onClick={() => {
                  setActivceTabIndex(12)
                  if (props.demoLogin) {
                    return toast.error(
                      _getLocalString('LOC_DEMO_ACCOUNT_ERROR_MESSAGE'),
                    )
                  }
                  navigate('/button-values')
                  handleClose()
                }}
                style={{
                  borderTop: 'solid 0.2px #8080802e',
                  background: activeTabIndex === 12 ? 'var(--theme-color)' : '',
                }}
                className='!px-10 !py-3'
              >
                <ListItemIcon>
                  <CasinoRounded
                    className={` ${activeTabIndex === 12 ? 'text-[white]' : 'text-[var(--theme-color)]'}  !w-7 !h-7`}
                  />
                </ListItemIcon>
                <div className='flex items-center justify-between w-full'>
                  <span
                    className={`${activeTabIndex === 12 ? 'text-[white]' : 'text-[black]'} font-dm_sans md:!text-sm !text-base `}
                  >
                    {_getLocalString('LOC_SET_BUTTON_VALUES')}
                  </span>
                </div>
              </MenuItem>
            )}

            {props.currentDomain.domainName !== 'tataexch247.com' && (
              <MenuItem
                onClick={() => {
                  setActivceTabIndex(13)
                  navigate('/complain-box')
                  handleClose()
                }}
                style={{
                  borderTop: 'solid 0.2px #8080802e',
                  background: activeTabIndex === 13 ? 'var(--theme-color)' : '',
                }}
                className='!px-10 !py-3'
              >
                <ListItemIcon>
                  <Receipt
                    className={` ${activeTabIndex === 13 ? 'text-[white]' : 'text-[var(--theme-color)]'}  !w-7 !h-7`}
                  />
                </ListItemIcon>
                <span
                  className={`${activeTabIndex === 13 ? 'text-[white]' : 'text-[black]'} font-dm_sans md:!text-sm !text-base `}
                >
                  {_getLocalString('LOC_COMPLAIN_BOX')}
                </span>
              </MenuItem>
            )}

            {props.currentDomain.domainName !== 'tataexch247.com' && (
              <MenuItem
                onClick={() => {
                  setActivceTabIndex(14)
                  navigate('/notification')
                  handleClose()
                }}
                style={{
                  borderTop: 'solid 0.2px #8080802e',
                  background: activeTabIndex === 14 ? 'var(--theme-color)' : '',
                }}
                className='!px-10 !py-3'
              >
                <ListItemIcon>
                  <NotificationAddTwoTone
                    className={` ${activeTabIndex === 14 ? 'text-[white]' : 'text-[var(--theme-color)]'}  !w-7 !h-7`}
                  />
                </ListItemIcon>
                <span
                  className={`${activeTabIndex === 14 ? 'text-[white]' : 'text-[black]'} font-dm_sans md:!text-sm !text-base `}
                >
                  {_getLocalString('LOC_NOTIFICATION')}
                </span>
              </MenuItem>
            )}

            {(userData.userType === USER_TYPES_ALL_OBJ.whiteLabel ||
              userData.userType === USER_TYPES_ALL_OBJ.administrator) && (
              <MenuItem
                onClick={() => {
                  setActivceTabIndex(15)
                  navigate('/news-letter')
                  handleClose()
                }}
                style={{
                  borderTop: 'solid 0.2px #8080802e',
                  background: activeTabIndex === 15 ? 'var(--theme-color)' : '',
                }}
                className='!px-10 !py-3'
              >
                <ListItemIcon>
                  <IoMailOutline
                    className={` ${activeTabIndex === 15 ? 'text-[white]' : 'text-[var(--theme-color)]'}  !w-7 !h-7`}
                  />
                </ListItemIcon>
                <span
                  className={`${activeTabIndex === 15 ? 'text-[white]' : 'text-[black]'} font-dm_sans md:!text-sm !text-base `}
                >
                  {_getLocalString('LOC_NEWS_LETTER')}
                </span>
              </MenuItem>
            )}

            {props.userType === 'administrator' && (
              <MenuItem
                className='!px-10 !py-3'
                style={{
                  borderTop: 'solid 0.2px #8080802e',
                  background: !props.appConfiguration.isMaintenance
                    ? '#3ea142'
                    : 'rgb(174 25 60)',
                }}
                onClick={() => {
                  setActivceTabIndex(16)
                  setMaintenceModal(true)
                }}
              >
                <ListItemIcon>
                  <Lock
                    style={{ color: activeTabIndex === 16 ? 'white' : 'black' }}
                    className='!w-7 !h-7'
                  />
                </ListItemIcon>
                <span
                  className={`${activeTabIndex === 16 ? 'text-[white]' : 'text-[black]'} font-dm_sans md:!text-sm !text-base `}
                >
                  {_getLocalString('LOC_TURN')}
                  <b>
                    {props.appConfiguration.isMaintenance ? ' OFF ' : ' ON '}
                  </b>
                  {_getLocalString('LOC_MAINTENANCE_MODE')}
                </span>
              </MenuItem>
            )}

            {props.userType === 'administrator' && (
              <MenuItem
                className='!px-10 !py-3'
                style={{
                  borderTop: 'solid 0.2px #8080802e',
                  background: props.appConfiguration.useSocket
                    ? '#3ea142'
                    : 'rgb(174 25 60)',
                }}
                onClick={() => {
                  setSocketModal(true)
                }}
              >
                <ListItemIcon>
                  <Lock style={{ color: 'black' }} className='!w-7 !h-7' />
                </ListItemIcon>
                <span className='text-white font-dm_sans md:!text-sm bold !text-base '>
                  {_getLocalString('LOC_TURN')}{' '}
                  <b>{props.appConfiguration.useSocket ? 'OFF ' : 'ON '}</b>
                  {_getLocalString('LOC_SOCKET')}
                </span>
              </MenuItem>
            )}

            {props.userType === 'administrator' && (
              <MenuItem
                style={{
                  borderTop: 'solid 0.2px #8080802e',
                  background: 'black',
                }}
                onClick={refreshUser}
                className='!px-10 !py-3'
              >
                <ListItemIcon>
                  <Refresh style={{ color: 'white' }} className='!w-7 !h-7' />
                </ListItemIcon>

                <span className='font-dm_sans text-white md:!text-sm !text-base '>
                  {_getLocalString('LOC_REFRESH_USER')}
                </span>
              </MenuItem>
            )}
          </Menu>
        </div>
        {!props.appConfiguration.isSocketBlocked ? (
          <div className='blinking-dot'></div>
        ) : (
          <div className='red-blinking-dot'></div>
        )}
      </div>

      <Modal
        className='fixed top-0 left-0 flex items-center justify-center w-full h-full overflow-scroll'
        open={isOpenModalChangePassword}
        onClose={() => setIsOpenModalChangePassword(false)}
      >
        <ChangePassword
          logoutUser={props.logoutUser}
          userType={props.userType}
          currentDomain={currentDomain}
          changePasswordFuncData={changePasswordFuncData}
          setIsChangePassword={setIsOpenModalChangePassword}
          changePassword={changePasswordFunc}
        />
      </Modal>
      <Dialog
        open={socketModal}
        onClose={() => setSocketModal(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {_getLocalString('LOC_TURN_ON_CONTENT')}{' '}
          <b>{props.appConfiguration.useSocket ? 'OFF ' : 'ON '}</b>{' '}
          {_getLocalString('LOC_SOCKET_CONNECTION')}
        </DialogTitle>
        <DialogTitle id='alert-dialog-title'>
          <label className='font-primary text-base font-medium leading-[30px] tracking-normal text-left  text-black'>
            {_getLocalString('LOC_TRANSACTION_PASSWORD')}
          </label>
          <div className='flex justify-center items-center relative w-full mb-3'>
            <input
              id='userNameTextBox__id'
              className='w-full rounded-md  p-1 !border-none border-white focus:outline-none px-4 py-2 bg-[#F1F1F1] text-black '
              type='text'
              value={transactionCode}
              onChange={(e: any) => setTransactionCode(e.target.value)}
            />
          </div>
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setSocketModal(false)}>Cancel</Button>
          <Button onClick={updateSocketUse} autoFocus>
            {_getLocalString('LOC_OK')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={maintenceModal}
        onClose={() => setMaintenceModal(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {_getLocalString('LOC_TURN_ON_CONTENT')}{' '}
          <b>{props.appConfiguration.isMaintenance ? 'OFF ' : 'ON '}</b>
          {_getLocalString('LOC_MAINTENANCE_MODE')}
        </DialogTitle>
        <DialogTitle id='alert-dialog-title'>
          <label className='font-primary text-base font-medium leading-[30px] tracking-normal text-left  text-black'>
            {_getLocalString('LOC_TRANSACTION_PASSWORD')}
          </label>
          <div className='flex justify-center items-center relative w-full mb-3'>
            <input
              id='userNameTextBox__id'
              className='w-full rounded-md  p-1 !border-none border-white focus:outline-none px-4 py-2 bg-[#F1F1F1] text-black '
              type='text'
              value={transactionCode}
              onChange={(e: any) => setTransactionCode(e.target.value)}
            />
          </div>
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setMaintenceModal(false)}>
            {_getLocalString('LOC_CANCEL')}
          </Button>
          <Button onClick={updateMaintenance} autoFocus>
            {_getLocalString('LOC_OK')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
